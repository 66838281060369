.box {
  height: 40px;
  display: flex;
  min-width: 230px;
  max-width: 250px;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 20px;
  align-items: center;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  margin-right: 15px;
}
/* .box:hover input {
  width: 350px;
} */
.box input {
  outline: none;
  border: none;
  font-weight: 500;
  transition: 0.8s;
  background: transparent;
}
.box .searchIcon {
  justify-content: flex-end;
}
