
.st0{fill:#FFCB1E;}
.st1{fill:#FFB8B8;}
.st2,.st4,.st7 {
    stroke:#3F0E8C;
    stroke-miterlimit:10;
}
.st2{fill:none;}
.st3{fill:#FFFFFF;stroke:#1D1D1B;stroke-width:0.9847;stroke-miterlimit:10;}
.st4{
    fill:#00D292;
}
.st5{fill:#00D292;}
.st6{fill:#00CCCB;}
.st7{fill:#00CCCB;}
.st8{fill:none;stroke:#1D1D1B;stroke-width:0.9847;stroke-miterlimit:10;}	
.st4,.st5,.st6{
    -webkit-animation: downMotion 8s ease-in-out infinite;
    -moz-animation: downMotion 8s ease-in-out infinite;
    animation: downMotion 8s ease-in-out infinite;
}	
.st0{
    -webkit-animation: upMotion 10s ease-in-out infinite;
    -moz-animation: upMotion 10s ease-in-out infinite;
    animation: upMotion 10s ease-in-out infinite;
}
.st7,.run{
    -webkit-animation: upMotion 6s ease-in-out infinite;
    -moz-animation: upMotion 6s ease-in-out infinite;
    animation: upMotion 6s ease-in-out infinite;
}
.rotate {
    transform-origin: 116px 88px;
    -webkit-animation: 4s rotate infinite linear;
    animation: 4s rotate infinite linear;
}   		
 @keyframes downMotion {
     0% {transform: translate(0, -15px);}
     50% {transform: translate(0, 0); }
     100% {transform: translate(0, -15px);}
  }
 @-webkit-keyframes downMotion {
     0% {transform: translate(0, -15px);}
     50% {transform: translate(0, 0); }
     100% {transform: translate(0, -15px);}
  }
 @-moz-keyframes downMotion {
     0% {transform: translate(0, -15px);}
     50% {transform: translate(0, 0); }
     100% {transform: translate(0, -15px);}
  } 		  		 
 @keyframes upMotion {
     0% {transform: translate(0, 15px);}
     50% {transform: translate(0, 0); }
     100% {transform: translate(0, 15px);}
  } 
 @-webkit-keyframes upMotion {
     0% {transform: translate(0, 15px);}
     50% {transform: translate(0, 0); }
     100% {transform: translate(0, 15px);}
  }  
 @-moz-keyframes upMotion {
     0% {transform: translate(0, 15px);}
     50% {transform: translate(0, 0); }
     100% {transform: translate(0, 15px);}
  }   		 		 
@keyframes rotate {100% {transform: rotate(360deg); } }
@-webkit-keyframes rotate {100% {-webkit-transform: rotate(360deg); } }
@-moz-keyframes rotate {100% {-webkit-transform: rotate(360deg); } } 
@-o-keyframes rotate {100% {-webkit-transform: rotate(360deg); } }		 		 	
