@import url("https://fonts.googleapis.com/css?family=Montserrat");

h3 {
  margin: 10px 0;
  color: #fff;
  text-transform: capitalize;
  text-decoration: none;
}

h6 {
  margin: 5px 0;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}

p {
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins, sans-serif";
}

.card-container {
  border-radius: 5px;
  background-color: #2f3aa3;
  color: #fff;
  padding-top: 30px;
  position: relative;
  width: 100%;
  max-width: 100%;
  text-align: center;
  margin-top: 14px;
}
.card-container .pro {
  color: #000;
  background-color: #fff;
  font-family: "Poppins, sans-serif";
  border-radius: 5px;
  font-size: 14px;
  padding: 3px 7px;
  width: 100px;
  position: absolute;
  top: 30px;
  left: 30px;
}

/* .skills .pro1 {
  color: #fff;
  background-color: #2f3aa3;
  font-family: "Poppins, sans-serif";
  border-radius: 5px;
  font-size: 14px;
  padding: 3px 7px;
  width: 100px;
  position: absolute;
  top: 30px;
  left: 30px;
}
 */
/* .card-container .round {
  border-radius: 50%;
  padding: 7px;
} */

button.primary {
  border-radius: 6px;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  padding: 10px 25px;
  outline: 0;
}

button.primary.ghost {
  background-color: transparent;
}

.skills {
  background-color: #fff;
  text-align: left;
  padding: 15px;
  margin-top: 30px;

  color: black;
}

.skills ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.skills ul li {
  border-width: 0;
  display: inline-block;
  justify-content: space-evenly;
  font-size: 14px;
  margin: 0 7px 7px 0;
  padding: 7px;
  font-weight: 600;
  gap: 2px;
}
.card-container .owner {
  color: #231e39;
  background-color: #fff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  padding: 3px 7px;
  /* position: absolute; */
  /* text-align: left;
  top: 30px;
  left: 30px; */
}
.span .owner-1 {
  color: #231e39;
  background-color: #febb0b;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  padding: 3px 7px;
  /* position: absolute; */
  /* text-align: left;
  top: 30px;
  left: 30px; */
}
