.formHeading {
    /* font-family: "arial"; */
    text-align: center;
    font-size: 1.7rem;
    font-weight: bold;
    margin-bottom: 1rem;

    margin-top: 50px;
    margin-bottom: 20px;
}


.sumbitBtn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.sumbitBtn button,
.printBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #64DCDC;
    border: 1px solid #64DCDC;
    padding: 10px 20px;
    border-radius: 5px;
    color: #fff;
    font-weight: 500;
    font-size: 1.5rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    margin-top: "10px";
    text-align: center;
}

.printBtn {
    padding: 5px 10px;

}

.printBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.invoice{
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    margin-top: 1rem;
}

.table{
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    margin-top: 1rem;
}