@import url("https://fonts.googleapis.com/css?family=Montserrat");

h3 {
  margin: 10px 0;
  font-weight: bold;
  text-decoration: underline;
  color: #fff;
  text-transform: capitalize;
}

h6 {
  margin: 5px 0;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}

p {
  font-size: 14px;
  line-height: 21px;
}

.card-container {
  background-color: #6da4e3;
  border-radius: 5px;
  box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.75);
  color: #fff;
  padding-top: 30px;
  position: relative;
  /* width: "100px"; */
  /* max-width: 100%; */
  text-align: center;
  margin-top: 14px;
}

.card-container .pro {
  color: #231e39;
  background-color: #febb0b;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  padding: 3px 7px;
  position: absolute;
  top: 30px;
  left: 30px;
}

.card-container .round {
  border: 1px solid #4b8edb;
  border-radius: 50%;
  padding: 7px;
}

button.primary {
  background-color: #4b8edb;
  border: 1px solid #4b8edb;
  border-radius: 6px;
  color: #fff;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  padding: 10px 25px;
  outline: 0;
}

button.primary.ghost {
  background-color: transparent;
  color: #02899c;
}

.skills {
  background-color: #6da4e3;
  text-align: left;
  padding: 15px;
  margin-top: 30px;
}

.skills ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.skills ul li {
  border: 1px solid #6da4e3;
  border-radius: 2px;
  display: inline-block;
  font-size: 15px;
  margin: 0 7px 7px 0;
  padding: 7px;
  font-weight: 600;
}

.right {
  position: sticky;
  overflow: hidden;
}
