/* print table.css */
.print-table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  .print-table td,
  .print-table th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  .print-table thead tr {
    background-color: #000;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  
  .print-table thead tr th {
    color: #f2f2f2;
  }
  
  @media print {
    .print-table thead {
      display: table-row-group;
    }
  }
  