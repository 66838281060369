:root {
  --meridiem-bg-color: #000000;
  --meridiem-text-color: #efaf0b;
  --dropdown-text-color: black;
  --hand-line-color: #efaf0b;
  --hand-circle-center: #efaf0b;
  --hand-circle-outer: #efaf0b;
  --hand-minute-circle: #efaf0b;
  --top-bg: black;
  --top-colon-color: #efaf0b;
  --top-text-color: #efaf0b;
  --top-selected-color: #efaf0b;
  --top-meridiem-color: #efaf0b;
  --numbers-text-color: #fff;
  --numbers-font-size-reg: 16px;
  --numbers-font-size-inner: 15px;
  --numbers-font-size-outer: 13px;
  --clock-wrapper-bg: black;
  --clock-bg: black;

  --meridiem-bg-color: null;
  --meridiem-text-color: #898989;
  --meridiem-selected-bg-color: #efaf0b;
  --meridiem-selected-text-color: black;

  --done-bg-color: black;
  --done-text-color: #efaf0b;
  --done-border-top: 1px solid #efaf0b;
  --done-font-size: 13px;
  --done-font-weight: 500;
}

.foo {
  --meridiem-bg-color: black;
  --meridiem-text-color: orange;
}

.react-timekeeper__clock-wrapper {
  background: black;
}

.some-wrapper .react-timekeeper__clock-wrapper {
  background: orange;
}

.CircularProgressbar-path {
  stroke: red;
}
.CircularProgressbar-trail {
  stroke: #efaf0b;
}
.CircularProgressbar-text {
  fill: yellow;
}
.CircularProgressbar-background {
  fill: #efaf0b;
}
.mainBackground {
  background-image: "https://png.pngtree.com/thumb_back/fh260/background/20200714/pngtree-modern-double-color-futuristic-neon-background-image_351866.jpg";
  filter: "blur(10px)";
  height: "100vh";
}
.secBackground {
  position: "absolute";
  top: "50%";
  left: "50%";
  transform: "translate(-50%, -50%)";
}
/* .bg-image {
  background-image: "https://img.rawpixel.com/s3fs-private/rawpixel_images/website_content/rm380-02-knyfs2py.jpg?w=800&dpr=1&fit=default&crop=default&q=65&vib=3&con=3&usm=15&bg=F4F4F3&ixlib=js-2.2.1&s=9f205a1370cdea0be1f828b496c3bb4b";

  /* Add the blur effect 
  filter: blur(8px);
  -webkit-filter: blur(8px);

  /* Full height 
  height: 100%;

  /* Center and scale the image nicely 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.front-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */
.loader_parent {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  z-index: 1111111;
  pointer-events: none;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 37px;
  font-weight: bold;
}

.loader_parent.blurred {
  display: block;
}

.blurred ~ * {
  filter: blur(3px);
}
input#datetimepicker {
  min-height: 1px;   
}
