.overlay {
  position: absolute;
  /* border: 2px solid red; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /*   height: 100%;
  width: 100%; */
  opacity: 0;
  transition: 0.5s ease;
  background-color: #e6e7f0;
  cursor: pointer;
}
.Img-container {
  position: relative;
}
.Img-container:hover .overlay {
  opacity: 1;
  cursor: pointer;
}
.text {
  color: red;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
