.forgot-password {
  font-size: 0.9rem;
  margin-top: 10px;
  color: #000;
  text-align: right;
  width: 200px;
  cursor: pointer;
}

.signup-text {
  color: #7c817c;
  font-size: 0.8rem;
  font-weight: 550;
  margin-top: 10px;
}
.paragraph-description {
  font-size: 0.7rem;
  margin: 0 38px;
  color: #7c817c;
  position: absolute;
  bottom: 30px;
  text-align: center;
  align-items: center;
}

.be-main {
  width: 0.5px;
  height: 28px;
  background-color: #d2d2d2;
  margin-left: 10px;
}

.input {
  width: 100%;
  border: none;
  outline: none;
  background-color: #f7f8ff;
}

.icon-email {
  width: 15px;
  height: 15px;
}
.icon-password {
  width: 35px;
  height: 55px;
  margin-top: 5px;
}

.icon-continue {
  fill: #2abe88;
  width: 15px;
  height: 15px;
}

.input-box label {
  position: absolute;
  text-align: left;
  font-size: 0.5rem;
  pointer-events: none;
  transition: 0.5s;
  font-size: 0.8rem;
  top: 2px;
  left: 0;
}

.input:focus ~ label,
.input:valid ~ label {
  font-size: 0.5rem;
  top: -11px;
}

.input-box {
  width: 250px;
  margin: 0 10px;
  position: relative;
}
.input-box2 {
  width: 120px;
  margin: 0 10px;
  position: relative;
}

.continue {
  background-color: #303f9f;
  color: white;
  border: none;
  width: 380px;
  height: 45px;
  border-radius: 11px;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;
}

.other-paragraph {
  color: #7c817c;
  font-size: 14px;
  text-align: center;
  font-weight: 550;
  margin-top: 10px;
}

.input-main {
  width: 380px;
  height: 52px;
  margin: auto;
  margin-top: 20px;
  border: 1px solid #303f9f;
  border-radius: 11px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.login-main {
  display: grid;
  place-items: center;
}
.login-main p {
  font-size: 1rem;
  margin-top: 8px;
  color: #303f9f;
  text-transform: capitalize;
  font-weight: bold;
  text-align: center;
}

.login-main h1 {
  font-size: 1.4rem;
}

.login-page-container {
  height: 100vh;
  background-color: #f7f8ff;
  transition: 0.5s;
  background-image: url("./entrypoint.svg");
  background-size: cover;
}

.detalis-main {
  text-align: center;
  float: right;
  width: 50%;
  height: 100%;
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
}

.logo {
  width: 205px;
  height: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 970px) {
  .login-page-container {
    background-image: none;
  }
  .detalis-main {
    width: 100%;
  }
  /* .login-page-container {
    display: flex;
    width: 500px;
    align-items: center;
    justify-content: center;
  } */
}

@media only screen and (max-width: 540px) {
  .login-page-container {
    width: 100%;
  }
}
