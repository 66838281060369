.dropdown {
  position: right;
}

.dropdown-toggle::after {
  content: " ▼";
}

.dropdown-menu {
  display: block;
  position: absolute;
  top: calc(100% + 4px);
  background-color: #f8f9fa;
  padding: 10px;
  min-width: 230px;
}

/* .dropdown-item {
    color: #333;
    cursor: pointer;
    border-radius: 20px;
  } */
.extra-item:hover {
  background-color: #e9ecef;
  border-radius: 20px;
  cursor: pointer;
  padding: 2px;
}
