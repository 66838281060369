.container-timeline ul,
li {
  list-style: none;
  padding: 0;
}
.main-timeline {
  /* display: flex; */
  /* justify-content: space-between; */
  background: #e6e7f0;
  border-radius: 15px;
  padding: 15px;
  margin-top: 20px;
  overflow: scroll;
  max-height: 78vh;
  /* align-items: center; */
}

.container-timeline {
  display: flex;
  justify-content: space-evenly;
  background: #e6e7f0;
  border-radius: 15px;
  margin-top: 15px;
  /* align-items: center; */
}

.wrapper-timeline {
  /* background: #eaf6ff; */
  /* padding: 0.8rem; */
  /* margin-right: 10px; */
  /* width: 100%; */
  /* display: flex;
    justify-content: center; */
}

.container-timeline h1 {
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
}
.other-part {
  /* padding-top:  0.2rem; 
   padding-right: 1rem; 
   padding-bottom: 1rem;  */
}

.other-part h1 {
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
}
.sessions {
  margin-top: 1rem;
  border-radius: 12px;
  position: relative;
}

.container-timeline li {
  padding-bottom: 10px;
  border-left: 1px solid #abaaed;
  position: relative;
  padding-left: 20px;
  margin-left: 10px;
}
.container-timeline li:last-child {
  border: 0px;
  padding-bottom: 0;
}
.container-timeline li:before {
  content: "";
  width: 15px;
  height: 15px;
  background: white;
  border: 1px solid #4e5ed3;
  box-shadow: 3px 3px 0px #bab5f8;
  box-shadow: 3px 3px 0px #bab5f8;
  border-radius: 50%;
  position: absolute;
  left: -10px;
  top: 0px;
}

.container-timeline .time {
  color: #2a2839;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #2e3094;
}
@media screen and (min-width: 601px) {
  .time {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 600px) {
  .time {
    margin-bottom: 0.3rem;
    font-size: 0.85rem;
  }
}

.container-timeline p {
  color: #4f4f4f;
  font-family: sans-serif;
  line-height: 1.3;
  margin-top: 0.4rem;
  border-top-left-radius: 40%;
}
.container-timeline img {
  height: 26px;
  width: 26px;
  border: "1px solid #e6e7f0";
  cursor: pointer;
  border-radius: 50%;
}
.container-timeline img {
  height: 26px;
  width: 26px;
  border: "1px solid #e6e7f0";
  cursor: pointer;
  border-radius: 50%;
}

@media screen and (max-width: 600px) {
  .container-timeline p {
    font-size: 0.9rem;
  }
}
